import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Helmet } from 'react-helmet-async';
import Contactbanner from '../assets/Contact us banner.jpeg';
import News from '../components/newsletter';
import Solimg from '../assets/bianca-ackermann-5-UxjBoRun4-unsplash 1.png';
import Insimg from '../assets/joshua-sortino-LqKhnDzSF-8-unsplash 1.png';
import './Contact.css';

const Contact = () => {
    document.title = 'Contact';

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        jobtitle: '',
        department: '',
        country: '',
        contact: '',
        purpose: '',
        objective: '',
        message: '',
        reference: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send('service_h1hhb55', 'template_yw1i0id', formData, 'l6vPLqmeRyWSqE6LN')
            .then((result) => {
                console.log('Email successfully sent:', result.text);
                alert('Your query has been submitted!');
                setFormData({
                    firstname: '',
                    lastname: '',
                    email: '',
                    jobtitle: '',
                    department: '',
                    country: '',
                    contact: '',
                    purpose: '',
                    objective: '',
                    message: '',
                    reference: '',
                }); // Clear form data
            }, (error) => {
               // console.log('Email sending error:', error.text);
                alert('There was an error sending your application. Please try again.');
            });
    };

    return (
        <section>
            <Helmet>
            <title>Contact Us</title>
            <meta name='description' content='Have questions or need assistance? Our team is ready and excited to assist you!' />
            </Helmet>
            <div className='ind-banner relative bg-slate-950 text-white h-96 overflow-hidden w-full'>
                <img className='h-full w-full object-cover object-top brightness-50' src={Contactbanner} alt='' />
                <div className="absolute z-40 top-2 left-2 xs:max-sm:left-0 xs:max-sm:px-5 flex flex-col w-8/12 xs:max-sm:w-full justify-center items-start p-8">
                    <div className="tracking-widest text-[3em] font-semibold text-[white] uppercase xs:max-sm:text-[2em]">
                        <h2>Get in Touch</h2>
                    </div>
                    <div className="text-sm xs:max-sm:text-[14px] w-fit">
                        <p>Have questions or need assistance?<br />Our team is ready and excited to assist you!</p>
                    </div>
                </div>
            </div>
            <p className='font-medium text-black py-8 text-lg w-full text-justify px-20 xs:max-sm:text-sm xs:max-sm:px-8'>
                We're always looking forward to having a chat! Just a few steps and we can set things sailing...
                <br />Let's connect and take the first step toward exploring, diving far beyond the technological horizon, and driving your success in this dynamic landscape.
            </p>
            <div className='flex flex-row items-center justify-start bg-slate-100 w-full h-full py-14 relative xs:max-sm:flex-col xs:max-sm:items-center xs:max-sm:py-0'>
                <div className='flex flex-col items-start justify-center gap-10 w-8/12 h-full bg-white bottom p-10 relative xs:max-sm:hidden'>
                    <div className="w-6/12 h-1/3 shade rounded p-6">
                        <p className="text-base text-black leading-loose">
                            Connect With Us:
                        </p>
                        <p className="text-base text-gray-600 leading-9">
                            For support or any questions:
                        </p>
                        <p className="text-base text-black leading-normal">
                            Email us at:
                        </p>
                        <p className="text-blue-600 leading-9">
                            <a href='mailto:info@expertlancing.com' className='text-base'>info@expertlancing.com</a>
                        </p>
                        <span className='text-base'>Or</span>
                        <p className="text-black leading-8 text-base">
                            Call us at:
                        </p>
                        <p className="text-blue-600 leading-9">
                            +1 973 561 5013 (US),<br/>
                            +91 124 420 9029 (IN)
                        </p>
                        <p className="text-black leading-7 text-base">
                            <i className="fa fa-map-marker" aria-hidden="true"></i> 1004 , 10th Floor, Iris Tech Park, Sohna Road, Gurugram
                        </p>
                    </div>
                    <a href='/IP-Consulting-Services' className='h-1/3 w-6/12 blockshade1 rounded overflow-hidden overlay relative'>
                        <img className='w-full h-full object-cover fil' src={Solimg} alt='sol-img' />
                        <div className='absolute text-white z-50 bottom-5 left-5 w-11/12 line-parent'>
                            <hr className='line' />
                            <p className='text-2xl font-semibold'>Solutions</p>
                            <p className='text-sm'>Discover our comprehensive services crafted to deliver exceptional results for your business.</p>
                        </div>
                    </a>
                    <a href='/insights' className='h-1/3 w-6/12 block shade2 rounded overflow-hidden overlay relative'>
                        <img className='w-full h-full object-cover fil' src={Insimg} alt='sol-img' />
                        <div className='absolute text-white z-50 bottom-5 left-5 w-11/12 line-parent'>
                            <hr className='line' />
                            <p className='text-2xl font-semibold'>Insights</p>
                            <p className='text-sm'>Gain valuable knowledge and strategic foresight with our in-depth insights and research.</p>
                        </div>
                    </a>

                    <div className='social absolute flex flex-row gap-5'>
                        <a href='https://www.linkedin.com/company/expertlancing-ip-services/'>
                            <i className="fa-brands fa-linkedin fa-2x text-red-800 text-2xl"></i>
                        </a>
                        <a href='https://www.youtube.com/@expertlancingresearchservi880'>
                            <i className="fa-brands fa-youtube fa-2x text-red-800 text-2xl"></i>
                        </a>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className='c-form w-6/12 bg-white absolute p-5 mr-10 flex flex-col gap-2 right-0 rounded xs:max-sm:w-full xs:max-sm:relative xs:max-sm:mr-0'>
                    <div className='flex flex-col gap-4 p-4'>
                        <div className='relative'>
                            <p className='font-semibold text-xs bg-white px-1 absolute -top-2 left-6'>Purpose of contacting us</p>
                            <select
                                className='inp-shadow w-full text-sm rounded bg-grey text-slate-400 cursor-pointer'
                                name='purpose'
                                value={formData.purpose}
                                onChange={handleChange}
                                required
                            >
                                <option value=''>Select an option</option>
                                <option value='Chemicals'>Chemicals</option>
                                <option value='Food & Nutrition'>Food & Nutrition</option>
                                <option value='Energy & Natural Resources'>Energy & Natural Resources</option>
                                <option value='Home & Personal Care'>Home & Personal Care</option>
                                <option value='Healthcare & Life Sciences'>Healthcare & Life Sciences</option>
                                <option value='Mobility'>Mobility</option>
                                <option value='Industrials'>Industrials</option>
                                <option value='Technology, Media & Telecom'>Technology, Media & Telecom</option>
                            </select>
                        </div>
                        <hr />
                        <div className='relative'>
                            <p className='font-semibold text-xs bg-white px-1 absolute -top-2 left-6'>Your business objectives</p>
                            <input
                                className='inp-shadow w-full rounded text-sm'
                                type='text'
                                name='objective'
                                value={formData.objective}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <hr />
                        <div className='w-full flex flex-col gap-y-5'>
                            <div className='flex flex-row gap-2'>
                                <div className='relative w-full'>
                                    <label htmlFor='firstname' className='font-semibold text-xs bg-white px-1 absolute -top-2 left-6'>First Name</label>
                                    <input
                                        className='inp-shadow rounded text-sm w-full'
                                        id='firstname'
                                        type='text'
                                        name='firstname'
                                        value={formData.firstname}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <label htmlFor='lastname' className='font-semibold text-xs bg-white px-1 absolute -top-2 left-6'>Last Name</label>
                                    <input
                                        className='inp-shadow rounded text-sm w-full'
                                        id='lastname'
                                        type='text'
                                        name='lastname'
                                        value={formData.lastname}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='relative w-full'>
                                <label htmlFor='email' className='font-semibold text-xs bg-white px-1 absolute -top-2 left-6'>Email</label>
                                <input
                                    className='inp-shadow w-full text-sm rounded'
                                    type='email'
                                    id='email'
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='relative w-full'>
                                <label htmlFor='jobtitle' className='font-semibold text-xs bg-white px-1 absolute -top-2 left-6'>Job Title</label>
                                <input
                                    className='inp-shadow w-full text-sm rounded'
                                    type='text'
                                    id='jobtitle'
                                    name='jobtitle'
                                    value={formData.jobtitle}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='relative w-full'>
                                <label htmlFor='department' className='font-semibold text-xs bg-white px-1 absolute -top-2 left-6'>Department</label>
                                <input
                                    className='inp-shadow w-full text-sm rounded'
                                    type='text'
                                    id='department'
                                    name='department'
                                    value={formData.department}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='relative w-full'>
                                <label htmlFor='country' className='font-semibold text-xs bg-white px-1 absolute -top-2 left-6'>Country</label>
                                <input
                                    className='inp-shadow w-full text-sm rounded'
                                    type='text'
                                    id='country'
                                    name='country'
                                    value={formData.country}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='relative w-full'>
                                <label htmlFor='contact' className='font-semibold text-xs bg-white px-1 absolute -top-2 left-6'>Contact Number</label>
                                <input
                                    className='inp-shadow w-full text-sm rounded'
                                    type='tel'
                                    id='contact'
                                    name='contact'
                                    value={formData.contact}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='relative w-full'>
                                <label htmlFor='reference' className='font-semibold text-xs bg-white px-1 absolute -top-2 left-6'>Reference</label>
                                <input
                                    className='inp-shadow w-full text-sm rounded'
                                    type='text'
                                    id='reference'
                                    name='reference'
                                    value={formData.reference}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='relative w-full'>
                                <label htmlFor='message' className='font-semibold text-xs bg-white px-1 absolute -top-2 left-6'>Message</label>
                                <textarea
                                    className='inp-shadow w-full text-sm rounded'
                                    id='message'
                                    name='message'
                                    value={formData.message}
                                    onChange={handleChange}
                                    rows='4'
                                    required
                                ></textarea>
                            </div>
                            <button type='submit' className='w-full bg-blue-600 text-white py-2 rounded'>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            <News />
        </section>
    );
};

export default Contact;
