import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useMediaQuery from '../components/useMediaQuery';
import { Brands } from '../components/brands';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import "./sep.css";

export const Sep = () => {
  const [activeTab, setActiveTab] = useState('tab1');
  const [filteredInsights, setFilteredInsights] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    const storedData = localStorage.getItem('filteredExcelData');
    if (storedData) {
      setFilteredInsights(JSON.parse(storedData));
    }
  }, []);

  const handleClick = () => {
    document.getElementById('more-txt').style.height = 'fit-content';
    document.getElementById('more-btn').style.display = 'none';
  }
  const handleClose = () => {
    document.getElementById('more-txt').style.height = '0px';
    document.getElementById('more-btn').style.display = 'block';
  }


  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "50px",
    slidesToShow: isSmallScreen ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    cssEase: "linear"
  };

  function ExcelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400 * 1000; // Convert to milliseconds
    const date_info = new Date(utc_value);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);
    const seconds = total_seconds % 60;
    total_seconds -= seconds;
    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    // Create a new date with valid values
    const year = date_info.getFullYear();
    const month = date_info.getMonth();
    const day = date_info.getDate();

    // Ensure the values are valid
    if (isNaN(year) || isNaN(month) || isNaN(day) ||
      isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return null; // or handle error as appropriate
    }

    return new Date(year, month, day, hours, minutes, seconds);

  }

  const insightsSlider = filteredInsights.map((insight, index) => (
    <Link key={index} className="cardcont" to={`/${insight.title.replaceAll(' ', '-')}`}>
      <div className='slidecard'>
        <img src={`/insights_img/${insight.main_image_all}`} alt={insight.title} />
        <div className="slidecard-txt h-full">
          <div>
            <span className="slidecard-tag">{insight.insight_type}</span>&nbsp;&nbsp;&nbsp;
            <span className="slidecard-tag">
              {format(new Date(ExcelDateToJSDate(insight.Submitted_on).toDateString()), 'EEEE, MMM, d, yyyy')}
            </span>
          </div>
          <h3>{insight.title}</h3>
          <p className="truncate" dangerouslySetInnerHTML={{ __html: insight.Abstract }}></p>
        </div>
      </div>
    </Link>
  ));

  const tabContents = {
    tab1: (
      <div className='section-details'>
        <div className='grid grid-cols-2 grid-rows-1 gap-10 mb-10'>
          <div className='flex flex-col items-start justify-between h-full gap-5'>
            <h2 className='font-customFont3 font-semibold text-3xl'>Beyond Prior Art: Transforming SEP Insights into <span className='text-red-700 font-customFont3 font-semibold text-3xl'>Actionable Intelligence</span></h2>
            <img className='h-48' src='/solution_img/sep-img1.png' alt=''/>
          </div>
          <div className='flex flex-col items-start justify-between gap-5'>
            <p className=''>
              Unlock the full potential of your Standard Essential Patents (SEPs) with our comprehensive suite of services, designed to provide clarity, strategic insights, and data-driven solutions every step of the way.
            </p>
            <div className='border-l-2 border-red-700 pl-5'>
              <p className="!font-semibold !text-xl">Essentiality Checks</p>
              <p>Confirming the Core of SEPs</p>
            </div>
            <div className='border-l-2 border-red-700 pl-5'>
              <p className="!font-semibold !text-xl">Validity & Invalidity Searches</p>
              <p>Strengthening Legal Positions</p>
            </div>
            <div className='border-l-2 border-red-700 pl-5'>
              <p className="!font-semibold !text-xl">Portfolio Evaluation</p>
              <p>Maximizing SEP Value</p>
            </div>
            <div className='border-l-2 border-red-700 pl-5'>
              <p className="!font-semibold !text-xl">Licensing Support & Strategy</p>
              <p>Data-Driven Decisions</p>
            </div>
          </div>
        </div>
        <div className='font-customFont2 mb-10'>
          <b className='font-customFont2 font-semibold text-xl'>Technology & Competitive Intelligence</b>
          <p>We track emerging technologies, market adoption, and competitor strategies to drive informed decision-making.</p>
          <ul className='pt-2'>
            <li className='list-custom-bullet'>Emerging Tech & Adoption – Analyzing standard-setting discussions to spot early trends and assess adoption for R&D and market positioning.</li>
            <li className='list-custom-bullet'>Competitor Strategy – Monitoring standardization activities, patent declarations, and key players to uncover strategic insights.</li>
            <li className='list-custom-bullet'>Future Market Moves – Identifying industry leaders shaping standards and predicting technology shifts.</li>
          </ul>
        </div>
        <p className='font-customFont2 font-light mb-10'>Our team holds expertise in diverse standards like <b className='text-red-700 font-customFont2'>3GPP, AV1, HEVC, IEEE, ITU, ETSI, MPEG, Matter, IETF</b> amongst other standards. Over the past years, our team has focused on several sub-domains, including:</p>
        <img className='mb-20 h-[135vh] w-auto object-cover' src={`/solution_img/sep-inner.jpg`} alt='' />
        
      </div>
    ),
    tab2: (
      <div className='section-details'>
        <div className='flex items-start justify-between gap-10 mb-10'>
          <h2 className='w-1/2 font-customFont3 font-semibold text-3xl'>Navigating SEPs like a pro—whether you’re enforcing, defending, or cutting the best deals, <span className='text-red-700 font-customFont3 font-semibold  text-3xl'>we’ve got your back.</span></h2>
          <p className='w-1/2'>The impact of Standard Essential Patent (SEP) extends across multiple industries and stakeholders. Whether you are enforcing SEPs, defending against infringement claims, navigating licensing agreements, or assessing compliance with global standards, <span className='text-red-700 font-customFont2'>ExpertLancing provides the expertise to support your strategic objectives.</span></p>
        </div>
        <div className='mb-20'>
          <p className='font-customFont2 !font-semibold !text-xl mb-10'>Lending our expertise to our valued clients across industries</p>
          <div className='grid grid-cols-3 grid-rows-2 gap-10'>
            <div className='sep-card w-full h-96 relative overflow-hidden'>
              <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-10/12">Technology Powerhouses & SEP Owners</p>
                <p className="sep-card-body pt-2 !text-sm opacity-0">Owning SEPs isn’t enough—proving their worth is the real challenge. We help innovators, R&D-driven enterprises, and standard-contributing companies validate and monetize their portfolios. From essentiality verification to portfolio benchmarking, our insights help SEP owners take control of licensing negotiations.</p>
                <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
              </div>
              <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/sep-tab2-card1.png`} alt='' />
            </div>
            <div className='sep-card w-full h-96 relative overflow-hidden'>
              <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-full">Law Firms Leading SEP Battles</p>
                <p className="sep-card-body pt-2 !text-sm opacity-0">SEP disputes are complex, and a misstep can cost millions. We empower litigation teams and IP attorneys with sharp, litigation-ready invalidity and essentiality analyses. For firms handling licensing negotiations, our insights bring clarity to FRAND compliance, essentiality claims, and portfolio positioning.</p>
                <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
              </div>
              <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/sep-tab2-card2.png`} alt='' />
            </div>
            <div className='sep-card w-full h-96 relative overflow-hidden'>
              <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-full">Licensing & Patent Monetization Leaders</p>
                <p className="sep-card-body pt-2 !text-sm opacity-0">SEP portfolios are valuable—if leveraged strategically. We assist licensing teams in validating essentiality claims, structuring licensing programs, and maximizing portfolio value.</p>
                <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
              </div>
              <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/sep-tab2-card3.png`} alt='' />
            </div>
            <div className='sep-card w-full h-96 relative overflow-hidden'>
              <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-full">Investors & M&A Due Diligence Teams</p>
                <p className="sep-card-body pt-2 !text-sm opacity-0">In high-stakes acquisitions, IP risk is financial risk. Our deep-dive SEP portfolio evaluations help private equity firms, venture capitalists, and M&A teams assess the strength, validity, and licensing potential of a target’s patent assets.</p>
                <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
              </div>
              <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/sep-tab2-card4.png`} alt='' />
            </div>
            <div className='sep-card w-full h-96 relative overflow-hidden'>
              <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-full">Product Manufacturers & Standard Implementers</p>
                <p className="sep-card-body pt-2 !text-sm opacity-0">Every device you build, every system you deploy—patents are in play. We work with OEMs, component manufacturers, and tech giants to cut through the complexity of SEPs.</p>
                <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
              </div>
              <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/sep-tab2-card5.png`} alt='' />
            </div>
            <div className='sep-card w-full h-96 relative overflow-hidden'>
              <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                <p className="!text-sm pb-2">Article</p>
                <p className="sep-card-head !font-customFont3 font-semibold !text-[1.5rem] w-full">How Open RAN is Revolutionizing and Democratizing Telecom Infrastructure</p>
                <p className="pt-2 !text-sm">Paving the Way for the Future of Telecommunications</p>
                <Link to={`/How-Open-RAN-is-Revolutionizing-and-Democratizing-Telecom-Infrastructure`} className='!text-xs !text-white absolute bottom-5 right-5 cursor-pointer'>Read more</Link>
              </div>
              <div className='z-20 w-full h-full object-cover bg-[rgb(0,50,170)] bg-[linear-gradient(315deg,_rgba(0,50,170,1)_0%,_rgba(0,24,56,1)_100%)]'></div>
            </div>
          </div>
        </div>
        <div className=''>
          <h2 className='font-customFont3 font-normal text-2xl'>We’re Proud to Work With</h2>
          <Brands />
          <p className='font-customFont2 w-full text-right mb-20'>...and many more!</p>
        </div>
      </div>
    ),
    tab3: (
      <div className='section-details'>
        <div className='flex items-start justify-between gap-10 mb-10'>
          <h2 className='w-1/2 font-customFont3 font-semibold text-3xl mb-10'>The <span className='font-customFont3 font-semibold text-3xl text-red-700'>ExpertLancing Advantage</span>: Mastering Standard Essential Patents (SEPs)</h2>
          <p className='w-1/2'>SEP analysis demands more than just technical expertise—it requires a strategic understanding of standards, industry dynamics, and legal implications. At ExpertLancing, <span className='font-customFont2 text-red-700'>we are the go-to experts for businesses</span> navigating the complex world of SEPs, with a track record that speaks for itself.</p>
        </div>
        <div className='mb-20'>
          <p className='font-customFont2 !font-semibold !text-xl mb-10'>Unmatched Impact with <span className='font-customFont2 !font-semibold !text-xl text-red-700'>Unrivaled Insights</span></p>
          <div className='grid grid-cols-2 grid-rows-2 gap-10'>
            <div className='flex flex-col items-center'>
              <img className='w-auto h-24' src={`/solution_img/lit-tab3-1.png`} alt='' />
              <p className="!font-semibold !text-xl text-center">Deep-Rooted Expertise,<br />Proven Results</p>
              <p className="pt-2 text-center">Our elite team of SEP specialists has successfully conducted thousands of essentiality checks, standard mappings, and prior art searches. We combine technical precision with real-world strategy, ensuring our clients receive the most defensible, high-impact insights for litigation, licensing, and portfolio management.</p>
            </div>
            <div className='flex flex-col items-center'>
              <img className='w-auto h-24' src={`/solution_img/lit-tab3-2.png`} alt='' />
              <p className="!font-semibold !text-xl text-center">Trusted for Accuracy:<br />From Second Opinions to First Choice</p>
              <p className="pt-2 text-center">Clients initially turned to us for second-opinion searches—seeking clarity after receiving unsatisfactory results elsewhere. Today, they rely on ExpertLancing as their primary SEP research partner, eliminating the need for a second search.</p>
            </div>
            <div className='flex flex-col items-center'>
              <img className='w-auto h-24' src={`/solution_img/lit-tab3-3.png`} alt='' />
              <p className="!font-semibold !text-xl text-center">Largest SEP Research Team:<br />Multi-Industry & Cross-Domain Mastery</p>
              <p className="pt-2 text-center">Unlike firms that limit SEP research to Hi-tech, we have built one of the largest, most diverse teams of SEP experts across industries.</p>
            </div>
            <div className='flex flex-col items-center'>
              <img className='w-auto h-24' src={`/solution_img/lit-tab3-4.png`} alt='' />
              <p className="!font-semibold !text-xl text-center">Beyond Search:<br />Strategic SEP Intelligence</p>
              <p className="pt-2 text-center">Our analysts are more than just researchers—they are technical strategists who help clients decode standard documents, assess essentiality, and strengthen the strategies.</p>
            </div>
          </div>
        </div>
        <div className='mb-10'>
          <div className='flex items-start justify-between gap-10 mb-10'>
            <h3 className='w-1/2 !font-semibold !text-3xl'><span className='font-customFont3 font-semibold text-3xl text-red-700'>STANVID</span>: AI & ML-Powered Standard Searching Tool</h3>
            <p className='w-1/2'>Being the largest and most experienced team of researchers in SEPs, we recognized the limitations of conventional standard searching, and developed <span className='font-customFont2 text-red-700'>STANVID</span>—a powerful <span className='font-customFont2 text-red-700'>AI & ML-driven</span> tool designed to enhance standard-essential patent analysis, reduce search time while improving accuracy and hence <span className='font-customFont2 text-red-700'>faster and accurate</span> delivery of reports.</p>
          </div>
          <div className='mb-10 grid grid-cols-2 grid-rows-1'>
            <div className='bg-[#1e1e1e] text-white p-5 border-t-4 border-[#f5f5f5]'>
              <p className='mb-5 font-customFont2 !font-semibold !text-xl'>The Challenge: Why Conventional Standard Searching Falls Short</p>
              <div className='flex flex-col items-start gap-2'>
                <div className='pt-5 flex items-center gap-10'>
                  <img className='w-auto h-10' src={`/solution_img/sep-regular1.png`} alt='' />
                  <div>
                    <p className="!font-semibold">Low search efficiency</p>
                    <p className="pt-2">Finding relevant technical documents can be slow and resource-intensive</p>
                  </div>
                </div>
                <div className='pt-5 flex items-center gap-10'>
                  <img className='w-auto h-10' src={`/solution_img/sep-regular2.png`} alt='' />
                  <div>
                    <p className="!font-semibold">Limited query capabilities</p>
                    <p className="pt-2">Traditional methods allow only basic search parameters</p>
                  </div>
                </div>
                <div className='pt-5 flex items-center gap-10'>
                  <img className='w-auto h-10' src={`/solution_img/sep-regular3.png`} alt='' />
                  <div>
                    <p className="!font-semibold">Time-consuming document retrieval</p>
                    <p className="pt-2">Downloading individual documents is cumbersome</p>
                  </div>
                </div>
                <div className='pt-5 flex items-center gap-10'>
                  <img className='w-auto h-10' src={`/solution_img/sep-regular8.png`} alt='' />
                  <div>
                    <p className="!font-semibold">Minimal filtering & result storage</p>
                    <p className="pt-2">Difficult to refine and manage search results effectively</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='bg-[#f5f5f5] p-5 border-t-4 border-red-700'>
              <p className='mb-5 font-customFont2 !font-semibold !text-xl'><span className='text-red-700 font-customFont2 !font-semibold !text-xl'>The Solution</span>: How <span className='text-red-700 font-customFont2 !font-semibold !text-xl'>STANVID</span> Transforms Standard Searching</p>
              <div className='flex flex-col items-start gap-2'>
                <div className='pt-5 flex items-center gap-10'>
                  <img className='w-auto h-10' src={`/solution_img/sep-regular5.png`} alt='' />
                  <div>
                    <p className="!font-semibold">AI & ML-Powered Search</p>
                    <p className="pt-2">Identifies arts & meeting documents efficiently</p>
                  </div>
                </div>
                <div className='pt-5 flex items-center gap-10'>
                  <img className='w-auto h-10' src={`/solution_img/sep-regular4.png`} alt='' />
                  <div>
                    <p className="!font-semibold">Sub-Search within Results</p>
                    <p className="pt-2">A game-changer for deeper standard document analysis</p>
                  </div>
                </div>
                <div className='pt-5 flex items-center gap-10'>
                  <img className='w-auto h-10' src={`/solution_img/sep-regular6.png`} alt='' />
                  <div>
                    <p className="!font-semibold">Assignee & Database Filters</p>
                    <p className="pt-2">Enhances search refinement for more targeted results</p>
                  </div>
                </div>
                <div className='pt-5 flex items-center gap-10'>
                  <img className='w-auto h-10' src={`/solution_img/sep-regular7.png`} alt='' />
                  <div>
                    <p className="!font-semibold">Secure & Private</p>
                    <p className="pt-2">Ensures confidentiality and data protection</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className='font-customFont2 font-normal'>By seamlessly integrating <span className='font-customFont2 font-normal text-red-700'>STANVID</span> into our workflow, we guarantee that <span className='font-customFont2 font-normal text-red-700'>every crucial standard document is captured and analyzed</span>, ensuring you receive the most thorough and <span className='font-customFont2 font-normal text-red-700'>accurate SEP insights</span> available. This integration strengthens our ability to provide you with a <span className='font-customFont2 font-normal text-red-700'>complete, up-to-date view</span> of your SEP landscape, leaving no stone unturned in delivering <span className='font-customFont2 font-normal text-red-700'>critical, actionable intelligence</span>.</p>
        </div>
      </div>
    ),
  };

  const handleTabChange = (id) => {
    setActiveTab(id);
  };
  return (
    <section className='flex flex-col items-center justify-center'>
      <Helmet>
        <title>Standard Essential Patents</title>
        {/* <meta name='description' content='Our team of experts at ExpertLancing excels in guiding businesses through industry challenges with foresight and precision. From market expansion to technology roadmapping, we craft solutions that fuel innovation and secure leadership.' /> */}
      </Helmet>
      <h1 className='text-[4em] font-customFont3 p-5 w-full'>Standard Essential Patents</h1>
      <div className='bg-red-600 flex items-start justify-between px-10 py-5' id='section-details'>
        <h2 className='text-[2em] font-semibold text-white w-[48%]'>Navigating the Complexity of Standard Essential Patents with Deep Domain Expertise.</h2>
        <div className='w-[48%]'>
          <p className='text-white font-customFont2 font-light'>Standard Essential Patents (SEPs) are crucial to modern connectivity and drive innovation across industries. As standardized technologies integrate into various sectors, companies face complex SEP challenges involving licensing, enforcement, and compliance with significant financial and strategic implications.
            <br /><br />At ExpertLancing, we simplify these complexities, offering Zero Defect essentiality checks, validity assessments, and actionable portfolio intelligence. Our research ensures technical precision in every patent assertion and licensing negotiation, providing the clarity and certainty needed when SEPs are at the center of a dispute.
          </p>
        </div>
      </div>
      <div className='sticky top-14 bg-white z-50 w-full shadow-sm xs:max-sm:hidden'>
        <ul className='flex items-center justify-center gap-28 w-full py-5'>
          <li><a href='#section-details' className={`${activeTab === 'tab1' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab1')}>What we do</a></li>
          <li><a href='#section-details' className={`${activeTab === 'tab2' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab2')}>Who we serve</a></li>
          <li><a href='#section-details' className={`${activeTab === 'tab3' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab3')}>How we do it differently</a></li>
        </ul>
      </div>
      <div className='hyperlink !p-2 mt-10' id='tabs'>
        {tabContents[activeTab]}
      </div>
      <div className='bg-red-600 flex items-start justify-between gap-10 p-10'>
        <h2 className='text-[2em] font-medium text-white w-1/2'>Faster, more relevant search results — <b className='font-bold text-white'>always within the top 25 hits, with STANVID</b>.</h2>
        <div className='w-1/2 flex flex-col items-start gap-10'>
          <p className='text-white font-customFont2 font-light pt-2'>StanVid makes analysis easy with a user-friendly interface that streamlines query management and effortlessly stores the search history.
          </p>
          <Link className='bg-white text-[#464646] p-2 hover:text-red-700' to={`/labs`}>Learn More</Link>
        </div>
      </div>

      <hr className='mt-10' id="related" />
      <h3 className='text-[1.5rem] font-customFont3 w-full text-left pl-12 xs:max-sm:text-[1.2em] font-normal my-10'>
        Related Articles
      </h3>
      <div className="slider-container w-11/12">
        {insightsSlider.length > 0 ? (
          <Slider {...settings}>{insightsSlider}</Slider>
        ) : (
          <p>No related articles found for "Standard Essential Patent".</p>
        )}
      </div>
    </section>
  )
}
