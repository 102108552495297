import React, { useState, useEffect } from "react";
import './Insight-report.css';
import emailjs from 'emailjs-com';
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useMediaQuery from '../components/useMediaQuery';
import fetchAndReadExcel from '../components/fetchAndReadExcel';
import { format } from 'date-fns';

import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterShareButton,
    XIcon,
} from 'react-share';


const InsightReport = ({ data }) => {
    const [insights, setInsights] = useState([]);
    const [insights_ccc, setinsights_ccc] = useState({});
    const [images, setImages] = useState([]);
    const insightsData = data;
    document.title = `${insightsData.title}`;
    const shareUrl = `${"expertlancing.com/" + insightsData.title.replaceAll(' ', '-')}`;
    const insighttitle = `${insightsData.title}`;
    const isSmallScreen = useMediaQuery('(max-width: 600px)');
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        designation: '',
        organization: '',
        comment: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send('service_61j96kk', 'template_oxjrgti', formData, 'KRfBTR-lzXt7JhBap')
            .then((result) => {
                console.log('Email successfully sent:', result.text);
                alert('Your query has been submitted!');
                setFormData({
                    fullname: '',
                    email: '',
                    designation: '',
                    organization: '',
                    comment: '',
                }); // Clear form data
            }, (error) => {
                // console.log('Email sending error:', error.text);
                alert('There was an error sending your application. Please try again.');
            });
    };

    useEffect(() => {
        const getData = async () => {
            const jsonData = await fetchAndReadExcel();
            let data = jsonData['data3'];

            // Sort the data by date (assuming the date field is named 'date' and is in ISO format)
            data = data.sort((a, b) => new Date(b.Submitted_on) - new Date(a.Submitted_on));

            setInsights(data);
        };
        getData();
    }, []);

    useEffect(() => {
        const processedData = {};
        const imageMap = {};
        for (let i = 1; i <= insightsData.NoofParagraphs; i++) {
            let headData = '', paraData = '';
            if (insightsData[`head_${i}`]) {
                headData = insightsData[`head_${i}`];
            }
            if (insightsData[`Para_${i}`]) {
                paraData = insightsData[`Para_${i}`];
            }
            if (insightsData[`image_${i}`]) {
                imageMap[insightsData[`image_${i}_loc`]] = insightsData[`image_${i}`];
            }
            processedData[i] = [headData, paraData];
        }
        setinsights_ccc(processedData);
        setImages(imageMap);
    }, [insightsData]);

    const imagePath = `/insights_img/${insightsData.main_image_all}`;

    for (let [key, value] of Object.entries(images)) {
        const index = key.slice(0, -1);
        if (insights_ccc[index]) {
            insights_ccc[index].push(value);
            insights_ccc[index].push(key.slice(-1));
        }
    }

    const sub_data = [];
    for (let [key, value] of Object.entries(insights_ccc)) {
        const iconUrl = `/insights_img/${value[2]}`;
        const imgClass = `head-img-${value[3]}`;
        sub_data.push((
            <>
                <h3 className="font-customFont4 text-[1.5rem] mb-[20px]" dangerouslySetInnerHTML={{ __html: value[0] }}></h3>
                <div className="mb-[34px]">
                    {value[2] && (
                        <img src={iconUrl} alt="" className={imgClass} />
                    )}
                    <p className="font-customFont2 font-light text-[16px] py-2 text-justify whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: value[1] }}></p>
                </div>
            </>
        ));
    }

    function ExcelDateToJSDate(serial) {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400 * 1000; // Convert to milliseconds
        const date_info = new Date(utc_value);

        const fractional_day = serial - Math.floor(serial) + 0.0000001;
        let total_seconds = Math.floor(86400 * fractional_day);
        const seconds = total_seconds % 60;
        total_seconds -= seconds;
        const hours = Math.floor(total_seconds / (60 * 60));
        const minutes = Math.floor(total_seconds / 60) % 60;

        // Create a new date with valid values
        const year = date_info.getFullYear();
        const month = date_info.getMonth();
        const day = date_info.getDate();

        // Ensure the values are valid
        if (isNaN(year) || isNaN(month) || isNaN(day) ||
            isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            return null; // or handle error as appropriate
        }

        return new Date(year, month, day, hours, minutes, seconds);

    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareUrl)
            .then(() => {
                alert("Link copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    };


    return (
        <section className="flex flex-col items-center">

            <div className='h-[70vh] w-full relative'>
                <div className="absolute left-10 top-10 z-20 flex flex-col gap-3 h-auto w-10/12">
                    <h1 className="text-white text-3xl font-customFont4 font-normal xs:max-sm:text-[1.2em]">{insightsData.title}</h1>
                    <h2 className="font-customFont2 font-light text-white text-[1em] italic text-left w-9/12">{insightsData.Abstract}</h2>
                    <div className="flex items-center gap-5">
                        <p className='text-white text-xs xs:max-sm:text-[14px] border border-white py-1 px-2 rounded w-fit'>{insightsData.insight_type}</p>
                        <a href="#form" className="text-white text-xs xs:max-sm:text-[14px] bg-red-700 border border-red-700 py-1 px-2 rounded w-fit">Get Report</a>
                        {/* {expert_pick} */}
                    </div>
                </div>
                <div className="absolute bottom-5 left-10 z-10 flex items-center justify-between w-[93%] xs:max-sm:flex-col-reverse xs:max-sm:items-start xs:max-sm:gap-5">
                    <div className="flex flex-row gap-8 xs:max-sm:gap-0">
                        <div className="flex flex-col border-solid border-gray-100 border-l-2 px-2 w-2/6">
                            <p className="text-white text-xxs">Written by</p>
                            <p className="flex items-center text-sm"><span className="text-white text-[14px] xs:max-sm:text-[10px]">{insightsData.Prepared_By}</span>
                            </p>
                        </div>

                        <div className="flex flex-col border-solid border-gray-100 border-l-2 px-2 w-2/6">
                            <p className="text-white text-xxs">Published on</p>

                            <p className="flex items-center text-sm"><span className="text-white text-[14px] xs:max-sm:text-[10px]"> {format(new Date(ExcelDateToJSDate(insightsData.Submitted_on).toDateString()), 'EEEE, MMM, d, yyyy')} </span></p>

                        </div>

                        <div className="flex flex-col border-solid border-gray-100 border-l-2 px-2 w-2/6">
                            <p className="text-white text-xxs">Reading Time</p>
                            <p className="flex items-center text-sm"><span className="text-white text-[14px] xs:max-sm:text-[10px]">{insightsData.Read_time} Minutes</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row gap-4 z-10">
                        <div className="Demo__some-network">
                            <FacebookShareButton
                                url={shareUrl}
                                className="Demo__some-network__share-button">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div className="Demo__some-network">
                            <TwitterShareButton
                                url={shareUrl}
                                title={insighttitle}
                                className="Demo__some-network__share-button"
                            >
                                <XIcon size={32} round />
                            </TwitterShareButton>
                        </div>

                        <div className="Demo__some-network">
                            <LinkedinShareButton
                                url={shareUrl}
                                title={insighttitle}
                                className="Demo__some-network__share-button">
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>

                        {/* New Copy Link Button */}
                        <div className="Demo__some-network">
                            <button
                                onClick={handleCopyLink}
                                className="Demo__some-network__share-button  bg-gray-500 px-2 py-1 rounded-full"
                            >
                                <span className="text-white"><i class="fa-regular fa-copy"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
                <img className='brightness-50 h-full w-full object-cover' src={imagePath} alt="Insight Banner" />
            </div>
            <div className="hyperlink report-analytics relative">
                <div className="relative bg-[#F9eeee] w-full h-60 rounded p-10 overflow-hidden">
                    <img className="opacity-70 w-2/12 -left-24 -top-24 absolute" src="/insights_img/circle-vector.png" alt="" />
                    <img className="opacity-70 w-2/12 left-80 -top-32 absolute" src="/insights_img/circle-vector.png" alt="" />
                    <img className="opacity-70 w-2/12 -right-16 -bottom-16 absolute" src="/insights_img/donut-vector.png" alt="" />
                    <img className="opacity-70 w-2/12 right-96 -bottom-20 absolute" src="/insights_img/kite-vector.png" alt="" />
                    <h3 className="z-50 pt-3">{insightsData.Report_para}</h3>
                </div>
                <div className="w-full px-10 flex items-start justify-between absolute -bottom-10">
                    <div className="p-3 flex flex-col items-center justify-start w-1/5 h-32 rounded shadow bg-white">
                        <p className="w-full !text-xs !font-semibold !text-[#464646]" dangerouslySetInnerHTML={{ __html: insightsData.Rep_Analytics_title1 }}></p>
                        <h3 className="!text-[2.5rem] !font-bold !text-[#c34c4d] pt-2" dangerouslySetInnerHTML={{ __html: insightsData.Rep_Analytics_num1 }}></h3>
                    </div>
                    <div className="p-3 flex flex-col items-center justify-start w-1/5 h-32 rounded shadow bg-white">
                        <p className="w-full !text-xs !font-semibold !text-[#464646]">{insightsData.Rep_Analytics_title2}</p>
                        <h3 className="!text-[2.5rem] !font-bold !text-[#c34c4d] pt-2">{insightsData.Rep_Analytics_num2}</h3>
                    </div>
                    <div className="p-3 flex flex-col items-center justify-start w-1/5 h-32 rounded shadow bg-white">
                        <p className="w-full !text-xs !font-semibold !text-[#464646]">{insightsData.Rep_Analytics_title3}</p>
                        <h3 className="!text-[2.5rem] !font-bold !text-[#c34c4d] pt-2">{insightsData.Rep_Analytics_num3}</h3>
                    </div>
                    <div className="w-1/5 h-32 rounded shadow relative overflow-hidden">
                        <div className="flex flex-col items-center justify-center gap-2 absolute top-[30%] w-full z-50">
                            <i className="fa-solid fa-lock text-[#464646]"></i>
                            <Link className="text-xs" to="#form">Unlock all insights</Link>
                        </div>
                        <div className="blur-[3px] p-3 flex flex-col items-center justify-start bg-white">
                            <p className="opacity-50 w-full !text-xs !font-semibold !text-[#464646]">Analytics title</p>
                            <h3 className="opacity-20 !text-[2.5rem] !font-bold !text-[#c34c4d] pt-2">54%</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-[75%] flex items-center justify-start gap-5 pt-20'>
            {Object.keys(insightsData).filter(key => key.startsWith('Industry')).map((key, index) => (
                <a className='bg-gray-100 text-[#464646] px-[0.7rem] py-[0.3rem] rounded-3xl font-customFont2 text-sm hover:bg-red-700 hover:text-white' href={`#related`} key={index}>{insightsData[key]}</a>
            ))}
            </div>

            <div className="hyperlink">
                <h3 className="!text-[1.75rem]">Key Questions</h3>
                <ul className="flex flex-col gap-5">
                    {Object.keys(insightsData).filter(key => key.startsWith('Key_Takeaways')).map((key, index) => (
                        <li className="list-custom-bullet font-customFont2 font-light text-[16px] leading-6" key={index} dangerouslySetInnerHTML={{ __html: insightsData[key] }}></li>
                    ))}
                </ul>
            </div>

            {/* <div className="hyperlink">
                <h3>Premise</h3>
                <p>Businesses in seemingly every industry are rapidly embracing artificial intelligence, particularly generative AI, to revolutionize their operations, enhance productivity, and drive innovation. According to <Link to="www.google.com">McKinsey’s latest Global Survey on AI</Link>, adoption of generative AI has nearly doubled in just ten months, with 65% of respondents reporting that their organizations are now regularly using the technology. From streamlining internal processes to creating cutting-edge customer experiences, the applications of AI are vast and impactful. Internally, companies are leveraging AI for tasks such as automated report generation, data analysis, and employee training programs. Externally, businesses are deploying AI-powered chatbots for customer service, using AI to personalize marketing campaigns, and even developing AI-assisted product design. The survey also reveals where businesses are seeing tangible benefits: the largest share of respondents report meaningful cost reductions from generative AI use in human resources, while revenue increases are most seen in supply chain and inventory management. As these powerful tools become more integrated into daily operations, a critical question looms: How do we ensure responsible and legal use of these groundbreaking technologies?</p>
            </div>
            <div className="hyperlink !gap-[10px]">
                <img className="w-20 opacity-20" src={`/insights_img/casestudy-quotes.png`} alt="" />
                <h3 className="!text-lg">The McKinsey survey underscores the significance of intellectual property (IP) concerns in the AI landscape. IP infringement emerges as the second-highest risk category associated with generative AI, surpassed only by inaccuracy.</h3>
            </div> */}
            {/* <div className="hyperlink">
                <h3>Premise</h3>
                <p>Businesses in seemingly every industry are rapidly embracing artificial intelligence, particularly generative AI, to revolutionize their operations, enhance productivity, and drive innovation. According to <Link to="www.google.com">McKinsey’s latest Global Survey on AI</Link>, adoption of generative AI has nearly doubled in just ten months, with 65% of respondents reporting that their organizations are now regularly using the technology. From streamlining internal processes to creating cutting-edge customer experiences, the applications of AI are vast and impactful. Internally, companies are leveraging AI for tasks such as automated report generation, data analysis, and employee training programs. Externally, businesses are deploying AI-powered chatbots for customer service, using AI to personalize marketing campaigns, and even developing AI-assisted product design. The survey also reveals where businesses are seeing tangible benefits: the largest share of respondents report meaningful cost reductions from generative AI use in human resources, while revenue increases are most seen in supply chain and inventory management. As these powerful tools become more integrated into daily operations, a critical question looms: How do we ensure responsible and legal use of these groundbreaking technologies?</p>
            </div> */}
            <div className="w-9/12 pt-[34px]">
                {sub_data}
            </div>

            <div className="hyperlink !mb-20">
                <h3>Get the full report</h3>
                <p>Unlock the full report – fill out the form and get your hands on the data!</p>
                <form onSubmit={handleSubmit} id="form" className="w-1/2 flex flex-col gap-5">
                    <label className="hidden"><input className="" type="checkbox" checked />Report_title</label>
                    <div className="relative border border-[#464646] rounded w-full h-10 flex flex-col justify-center">
                        <label className="absolute -top-2 left-2 text-xs bg-white px-2" htmlFor="name">Name<span className="text-red-600">*</span></label>
                        <input className="ml-5 outline-none border-none"
                            placeholder="Enter your name"
                            id='fullname'
                            type='text'
                            name='fullname'
                            value={formData.fullname}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="relative border border-[#464646] rounded w-full h-10 flex flex-col justify-center">
                        <label className="absolute -top-2 left-2 text-xs bg-white px-2" htmlFor="email">Email<span className="text-red-600">*</span></label>
                        <input className="ml-5 outline-none border-none" placeholder="Enter your name"
                            id='email'
                            type='email'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="relative border border-[#464646] rounded w-full h-10 flex flex-col justify-center">
                        <label className="absolute -top-2 left-2 text-xs bg-white px-2" htmlFor="designation">Designation</label>
                        <input className="ml-5 outline-none border-none" placeholder="Enter your designation"
                            id='designation'
                            type='text'
                            name='designation'
                            value={formData.designation}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="relative border border-[#464646] rounded w-full h-10 flex flex-col justify-center">
                        <label className="absolute -top-2 left-2 text-xs bg-white px-2" htmlFor="org">Organization</label>
                        <input className="ml-5 outline-none border-none" placeholder="Enter the name of your Organization"
                            id='org'
                            type='text'
                            name='organization'
                            value={formData.organization}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="relative border border-[#464646] h-40 rounded w-full flex flex-col justify-center">
                        <label className="absolute -top-2 left-2 text-xs bg-white px-2" htmlFor="comments">Comments (optional)</label>
                        <textarea className="m-5 h-full outline-none border-none" placeholder="Enter your message"
                            id='comment'
                            type='text'
                            name='comment'
                            value={formData.comment}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="w-full bg-gradient-to-r from-red-600 via-red-800 to-red-600 text-white py-3 px-12 shadow-lg rounded block transition-all duration-500 hover:bg-gradient-to-l hover:bg-[length:200%_100%]">
                        Get Report
                    </button>
                </form>
            </div>
        </section>
    );
}

export default InsightReport;