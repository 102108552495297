import React from 'react';
import { createBrowserRouter, ScrollRestoration } from 'react-router-dom';
import Navbar from './components/Nav';
import Footer from './components/Footer';
import Home from './pages/Homepage';
import SolutionsContent from './pages/SolutionsContent';
import Contact from './pages/Contact';
import Elabs from './pages/Elabs';
import Insights from './pages/Insights';
import IndustryCont from './pages/IndustryContent';
import SubIndustry from './pages/SubIndustry';
import Career from './pages/Career';
import About from './pages/About';
import InsightSubpage from './pages/Insight-subpage';
import Ourpeople from './pages/Ourpeople';
import ElabsVM from './pages/ElabsVM';
import Sustainability from './pages/Sustainability';
import ExpertCorner from './pages/ExpertCorner';
import InsightCasestudy from './pages/Insight-casestudy';
import InsightArticle from './pages/Insight-article';
import InsightReport from './pages/Insight-report';
import { Sep } from './pages/Sep';
import { Litigation } from './pages/Litigation';
import { Licensing } from './pages/Licensing';

export const createRoutes = (industry, services, insights) => {
  const pathmaker = [
    { path: "/", element: <><ScrollRestoration /><Navbar /><Home data={insights} /><Footer /></> },
    { path: "/contact", element: <><ScrollRestoration /><Navbar /><Contact /><Footer /></> },
    { path: "/career", element: <><ScrollRestoration /><Navbar /><Career /><Footer /></> },
    { path: "/aboutus", element: <><ScrollRestoration /><Navbar /><About /><Footer /></> },
    { path: "/labs", element: <><ScrollRestoration /><Navbar /><Elabs /><Footer /></> },
    { path: "/elabs/visionmap", element: <><ScrollRestoration /><Navbar /><ElabsVM /><Footer /></> },
    { path: "/insights", element: <><ScrollRestoration /><Navbar /><Insights /><Footer /></> },
    { path: "/ourpeople", element: <><ScrollRestoration /><Navbar /><Ourpeople /><Footer /></> },
    { path: "/sustainability", element: <><ScrollRestoration /><Navbar /><Sustainability /><Footer /></> },
    { path: "/expertcorner", element: <><ScrollRestoration /><Navbar /><ExpertCorner /><Footer /></> },
    { path: "/standard-essential-patent", element: <><ScrollRestoration /><Navbar /><Sep /><Footer /></> },
    { path: "/litigation", element: <><ScrollRestoration /><Navbar /><Litigation /><Footer /></> },
    { path: "/licensing", element: <><ScrollRestoration /><Navbar /><Licensing /><Footer /></> }
  ];

  industry.forEach(industryData => {
    Object.keys(industryData).forEach(key => {
      if (key.includes('Sub_category')) {
        const createData = {
          title: industryData[key],
          banner: industryData[key + '_banner'],
          tagline: industryData[key + '_tagline'],
          overview: industryData[key + '_overview'],
          infographic: industryData[key + '_infographic'],
          testimonial: industryData[key + '_testimonial'],
          testimonial_name: industryData[key + '_testimonial_name'],
          testimonial2: industryData[key + '_testimonial2'],
          testimonial_name2: industryData[key + '_testimonial_name2'],
          testimonial3: industryData[key + '_testimonial3'],
          testimonial_name3: industryData[key + '_testimonial_name3'],
        };

        pathmaker.push({
          path: "/" + industryData[key].replaceAll(' ', '-'),
          element: <><ScrollRestoration /><Navbar /><SubIndustry data={createData} /><Footer /></>
        });
      }
    });
  });

  services.forEach(service => {
    pathmaker.push({
      path: "/" + service.Name.replaceAll(' ', '-'),
      element: <><ScrollRestoration /><Navbar /><SolutionsContent data={service} /><Footer /></>
    });
  });

  // Object.keys(insights).forEach(key => {
  //   pathmaker.push({
  //     path: "/" + insights[key].title.replaceAll(' ', '-'),
  //     element: <><ScrollRestoration /><Navbar /><InsightSubpage data={insights[key]} /><Footer /></>
  //   });
  // });


  Object.keys(insights).forEach(key => {
    const { title, insight_name } = insights[key]; // Destructure title and type for readability
  
    // Determine which component/template to use based on type
    const element = insight_name === "New Case Study"
    ? (
        <>
          <ScrollRestoration />
          <Navbar />
          <InsightCasestudy data={insights[key]} />
          <Footer />
        </>
      )
    : insight_name === "New Article"
    ? (
        <>
          <ScrollRestoration />
          <Navbar />
          <InsightArticle data={insights[key]} />
          <Footer />
        </>
      )
      : insight_name === "Report"
      ? (
          <>
            <ScrollRestoration />
            <Navbar />
            <InsightReport data={insights[key]} />
            <Footer />
          </>
        )  
    : (
        <>
          <ScrollRestoration />
          <Navbar />
          <InsightSubpage data={insights[key]} />
          <Footer />
        </>
      );
  
    // Push the appropriate configuration into pathmaker
    pathmaker.push({
      path: "/" + title.replaceAll(' ', '-'),
      element
    });
  });

  industry.forEach(industryData => {
    pathmaker.push({
      path: "/" + industryData.Name.replaceAll(' ', '-'),
      element: <><ScrollRestoration /><Navbar /><IndustryCont data={industryData} /><Footer /></>
    });
    // console.log(industryData.Name.replaceAll(' ', '-'))
  });

  return createBrowserRouter(pathmaker);
};
