import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useMediaQuery from '../components/useMediaQuery';
import { format } from 'date-fns';
import { Brands } from '../components/brands';
import { Helmet } from 'react-helmet-async';
import "./sep.css";

export const Litigation = () => {
  const [activeTab, setActiveTab] = useState('tab1');
  const handleClick = () => {
    document.getElementById('more-txt').style.height = 'fit-content';
    document.getElementById('more-btn').style.display = 'none';
  }
  const handleClose = () => {
    document.getElementById('more-txt').style.height = '0px';
    document.getElementById('more-btn').style.display = 'block';
  }

  const [filteredInsights, setFilteredInsights] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    const storedData = localStorage.getItem('filteredExcelData');
    if (storedData) {
      setFilteredInsights(JSON.parse(storedData));
    }
  }, []);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "50px",
    slidesToShow: isSmallScreen ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    cssEase: "linear"
  };

  function ExcelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400 * 1000; // Convert to milliseconds
    const date_info = new Date(utc_value);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);
    const seconds = total_seconds % 60;
    total_seconds -= seconds;
    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    // Create a new date with valid values
    const year = date_info.getFullYear();
    const month = date_info.getMonth();
    const day = date_info.getDate();

    // Ensure the values are valid
    if (isNaN(year) || isNaN(month) || isNaN(day) ||
      isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return null; // or handle error as appropriate
    }

    return new Date(year, month, day, hours, minutes, seconds);

  }

  const insightsSlider = filteredInsights.map((insight, index) => (
    <Link key={index} className="cardcont" to={`/${insight.title.replaceAll(' ', '-')}`}>
      <div className='slidecard'>
        <img src={`/insights_img/${insight.main_image_all}`} alt={insight.title} />
        <div className="slidecard-txt h-full">
          <div>
            <span className="slidecard-tag">{insight.insight_type}</span>&nbsp;&nbsp;&nbsp;
            <span className="slidecard-tag">
              {format(new Date(ExcelDateToJSDate(insight.Submitted_on).toDateString()), 'EEEE, MMM, d, yyyy')}
            </span>
          </div>
          <h3>{insight.title}</h3>
          <p className="truncate" dangerouslySetInnerHTML={{ __html: insight.Abstract }}></p>
        </div>
      </div>
    </Link>
  ));

  const tabContents = {
      tab1: (
          <div className='section-details'>
            <div className='flex items-start justify-between gap-10 mb-10'>
              <h2 className='w-1/2 font-customFont3 font-semibold text-3xl'>IP litigation demands more than prior art—it requires strategy. We don’t just find prior art—<span className='font-customFont3 font-semibold text-3xl text-red-700'>we find the prior art that matters.</span></h2>
              <div className='w-1/2'>
                <p>
                  Patent invalidation is not just about searching databases—it is about understanding claims, dissecting patents, and building a targeted strategy that aligns with litigation objectives. <span className='font-customFont2 text-red-700'>At ExpertLancing, we go beyond</span> conventional search methodologies to conduct litigation-grade invalidation searches that uncover high-impact, admissible prior art—art that stands up to legal scrutiny and influences case outcomes.
                </p>
                <button id='more-btn' onClick={handleClick} className='font-customFont2 underline font-light'>See more</button>
              </div>
            </div>
            <div className='h-0 overflow-hidden mb-10' id='more-txt'>
              <p>
                Every invalidity search at ExpertLancing is driven by a deep understanding of claim construction, legal standards, and industry-specific nuances. Our research methodology extends beyond patent literature, identifying prior art across global patent filings, industry standards, product documentation, regulatory disclosures, and foreign-language sources. When necessary, we examine product timelines, reverse-engineering 
                  disclosures, and archived technical content to uncover non-patent literature (NPL) that predates the patent in question.
                <br/><br/>
                <span className='font-customFont2 text-red-700'>
                ExpertLancing’s approach is claim-by-claim, detail-driven, and litigation-focused</span>, ensuring that the prior art identified is not just relevant, but also legally admissible and strategically impactful. Our expertise spans multiple industries and technologies, allowing us to deliver precisely mapped technical disclosures that strengthen invalidity arguments, support reexamination strategies, and provide robust defenses in high-stakes disputes.
              <br/><br/>In litigation, precision and defensibility define success. At ExpertLancing, we don’t just conduct invalidation searches—<span className='font-customFont2 text-red-700'>we strategize, anticipate challenges, and equip legal teams with actionable intelligence</span> that enhances their position well before entering the courtroom. With a litigation-driven approach, deep technical expertise, and a commitment to delivering results that matter, <span className='font-customFont2 text-red-700'>we help our clients take control</span> of complex patent disputes with confidence.
              </p>
              <button onClick={handleClose} className='font-customFont2 underline font-light'>See less</button>
            </div>
          </div>
      ),
      tab2: (
          <div className='section-details'>
            <div className='flex items-start justify-between gap-10 mb-10'>
              <h2 className='w-1/2 font-customFont3 font-semibold text-3xl'>Our clients are industry leaders, pioneering corporations, and <span className='font-customFont3 font-semibold text-3xl text-red-700'>the sharpest legal minds</span> in the world.</h2>
              <div className='w-1/2'>
                <p>
                <span className='font-customFont2 text-red-700'>At ExpertLancing, we support those who define the benchmarks of innovation, strategy, and legal excellence</span>—companies and law firms that demand accuracy, depth, and strategic foresight in every case. Their pursuit of excellence drives us to uphold the same rigorous standards, delivering meticulously researched insights that are not only comprehensive but actionable and litigation-ready.
                </p>
                <button id='more-btn' onClick={handleClick} className='font-customFont2 underline font-light'>See more</button>
              </div>
            </div>
            <div className='h-0 overflow-hidden mb-10' id='more-txt'>
              <p>
              Our expertise extends beyond conventional IP research, supporting high-stakes litigation, market-defining patents, and complex technical challenges that shape industries. With a <span className='font-customFont2 text-red-700'>global reach</span>, we help clients navigate IP disputes, prior art searches, and litigation strategy with <span className='font-customFont2 text-red-700'>precision, efficiency, and an unwavering commitment to quality</span>.
              <br/><br/>Every case is handled by a specialized team with deep domain expertise—not just researchers, but strategic partners who understand the nuances of patent law, litigation tactics, and competitive landscapes. This approach ensures that the insights we deliver are not only legally relevant and admissible but also strategically impactful.
                <br/><br/>The world’s most innovative companies and top-tier law firms <span className='font-customFont2 text-red-700'>trust ExpertLancing</span> to uncover high-value prior art, analyze patent strength, and provide data-driven intelligence that shapes litigation strategy. Whether strengthening a case, dismantling a patent, or uncovering hidden technical disclosures, <span className='font-customFont2 text-red-700'>our expertise drives results where it matters most.</span>
              </p>
              <button onClick={handleClose} className='font-customFont2 underline font-light'>See less</button>
            </div>
            <div className='mb-10'>
              <div className='flex items-start justify-between gap-10 mb-5'>
                <h2 className='w-1/2 font-customFont3 font-semibold text-3xl mb-10'>Our clients don’t come to us for generic searches. They come to us because they need <span className='font-customFont3 font-semibold text-3xl text-red-700'>results</span>.</h2>
                <p className='w-1/2'>
                At ExpertLancing, we believe in going <span className='font-customFont2 text-red-700'>beyond the horizon</span> to provide our clients with tangible results. We deliver customized, high-impact solutions that drive success and exceed expectations.
                </p>
              </div>
              <div className='grid grid-cols-3 grid-rows-2 gap-10'>
                  <div className='sep-card w-full h-96 relative overflow-hidden'>
                    <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                      <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-10/12">
                      Litigation Teams
                      Fighting Billion-Dollar Patent Cases</p>
                      <p className="sep-card-body pt-2 !text-sm opacity-0">When every argument will be dissected in court, we deliver invalidity searches that withstand the highest scrutiny.</p>
                      <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
                    </div>
                    <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/lit-tab2-card1.png`} alt=''/>
                  </div>
                  <div className='sep-card w-full h-96 relative overflow-hidden'>
                    <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                      <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-full">
                        Corporate IP and
                      Legal Teams Under Attack </p>
                      <p className="sep-card-body pt-2 !text-sm opacity-0">Whether facing a lawsuit, responding to licensing demands, or countering SEP assertions, we provide evidence that neutralizes threats and safeguards market positions.</p>
                      <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
                    </div>
                    <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/lit-tab2-card2.png`} alt=''/>
                  </div>
                  <div className='sep-card w-full h-96 relative overflow-hidden'>
                    <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                      <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-full">
                        Companies Preparing for
                      IPR, PGR, and PTAB Challenges </p>
                      <p className="sep-card-body pt-2 !text-sm opacity-0">Post-grant proceedings require bulletproof prior art that aligns with procedural requirements. We deliver searches built specifically for PTAB success.</p>
                      <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
                    </div>
                    <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/lit-tab2-card3.png`} alt=''/>
                  </div>
                  <div className='sep-card w-full h-96 relative overflow-hidden'>
                    <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                      <p className="sep-card-head !font-customFont3 font-semibold !text-[1.7rem] w-full">
                        Defendants in
                      High-Stakes SEP Litigation </p>
                      <p className="sep-card-body pt-2 !text-sm opacity-0">Our targeted invalidity strategies challenge the essentiality of patents, ensuring compliance with FRAND obligations and preventing excessive royalty demands.</p>
                      <p className='sep-card-bait !text-xs absolute bottom-5 right-5 cursor-default'>See more</p>
                    </div>
                    <img className='z-20 w-full brightness-50 h-full object-cover' src={`/solution_img/lit-tab2-card4.png`} alt=''/>
                  </div>
                  <div className='sep-card w-full h-96 relative overflow-hidden'>
                    <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                      <p className="!text-sm pb-2">Case Study</p>
                      <p className="sep-card-head !font-customFont3 font-semibold !text-[1.5rem] w-full">Resolving Complex IP Litigation</p>
                      <p className="pt-2 !text-sm">Navigating a High-Stakes Patent Battle in Healthcare</p>
                      <Link to={`/Resolving-Complex-IP-Litigation`} className='!text-xs !text-white absolute bottom-5 right-5 cursor-pointer'>Read more</Link>
                    </div>
                    <div className='z-20 w-full h-full object-cover bg-[rgb(0,50,170)] bg-[linear-gradient(315deg,_rgba(0,50,170,1)_0%,_rgba(0,24,56,1)_100%)]'></div>
                  </div>
                  <div className='sep-card w-full h-96 relative overflow-hidden'>
                    <div className='sep-card-txt w-full h-full z-40 absolute text-white p-5'>
                      <p className="!text-sm pb-2">Article</p>
                      <p className="sep-card-head !font-customFont3 font-semibold !text-[1.5rem] w-full">Avoiding Pitfalls in SEP Litigation: Best Practices for Ensuring Comprehensive Prior Art Coverage</p>
                      <p className="pt-2 !text-sm">Finding Prior Art Where No One Looks</p>
                      <Link to={`/Avoiding-Pitfalls-in-SEP-Litigation:-Best-Practices-for-Ensuring-Comprehensive-Prior-Art-Coverage`} className='!text-xs !text-white absolute bottom-5 right-5 cursor-pointer'>Read more</Link>
                    </div>
                    <div className='z-20 w-full h-full object-cover bg-[rgb(0,50,170)] bg-[linear-gradient(315deg,_rgba(0,50,170,1)_0%,_rgba(0,24,56,1)_100%)]'></div>
                  </div>
                </div>
              </div>
              <div className=''>
                <h2 className='font-customFont3 font-normal text-2xl'>We’re Proud to Work With</h2>
                <Brands/>
                <p className='font-customFont2 w-full text-right'>...and many more!</p>
              </div>
            </div>
      ),
      tab3: (
          <div className='section-details'>
            <div className='flex items-start justify-between gap-10 mb-10'>
              <h2 className='w-1/2 font-customFont3 font-semibold text-3xl'>If prior art exists, <span className='font-customFont3 font-semibold text-3xl text-red-700'>we will find it</span>. If it doesn’t exist, we will still find a way to weaken the claims.</h2>
              <div className='w-1/2'>
                <p>
                When billions of dollars, entire product lines, and competitive market positions are at stake, a generic search won’t suffice. Our <span className='font-customFont2 text-red-700'>specialized litigation task force</span>, comprised of experts with over a decade of prior art search experience, ensures that every search is litigation-ready, defensible, and strategically aligned with case objectives —<span className='font-customFont2 text-red-700'>evidence that can make the difference between victory and defeat</span>.
                </p>
                <button id='more-btn' onClick={handleClick} className='font-customFont2 underline font-light'>See more</button>
              </div>
            </div>
            <div className='h-0 overflow-hidden mb-10' id='more-txt'>
              <p>Most firms run patent searches. <span className='font-customFont2 text-red-700'>We conduct strategic investigations.</span> Our process is built around <span className='font-customFont2 text-red-700'>a litigation-first mentality</span> that considers not just what prior art exists, but how it can be used effectively in court.
                <br/><br/>Every search we conduct follows a meticulous, structured approach, but what truly sets us apart is our attitude towards invalidation. We challenge assumptions, think beyond conventional sources, and push boundaries to uncover prior art that changes the trajectory of a case.
              </p>
              <button onClick={handleClose} className='font-customFont2 underline font-light'>See less</button>
            </div>
            <div className='mb-20'>
            <p className='font-customFont2 !font-semibold !text-xl mb-10'>We <span className='text-red-700 font-customFont2 !font-semibold !text-xl'>approach</span> each invalidity search with</p>
            <div className='grid grid-cols-2 grid-rows-2 gap-10'>
                <div className='flex flex-col items-center'>
                  <img className='w-auto h-24' src={`/solution_img/lit-tab3-1.png`} alt=''/>
                  <p className="!font-semibold !text-xl">The Mindset of a Litigator</p>
                  <p className="pt-2 text-center">Every reference we find is evaluated not just for relevance, but for how it strengthens your argument in court. We think about cross-examination, claim construction, and admissibility from the very start.</p>
                </div>
                <div className='flex flex-col items-center'>
                  <img className='w-auto h-24' src={`/solution_img/lit-tab3-2.png`} alt=''/>
                  <p className="!font-semibold !text-xl">The Persistence of an Investigator</p>
                  <p className="pt-2 text-center">Whether facing a lawsuit, responding to licensing demands, or countering SEP assertions, we provide evidence that neutralizes threats and safeguards market positions.</p>
                </div>
                <div className='flex flex-col items-center'>
                  <img className='w-auto h-24' src={`/solution_img/lit-tab3-3.png`} alt=''/>
                  <p className="!font-semibold !text-xl">The Precision of an Engineer</p>
                  <p className="pt-2 text-center">Understanding the technology is just as critical as understanding the law. Our team of technical experts deconstructs patents, analyzes claim language, and identifies the smallest technical details that can dismantle an assertion.</p>
                </div>
                <div className='flex flex-col items-center'>
                  <img className='w-auto h-24' src={`/solution_img/lit-tab3-4.png`} alt=''/>
                  <p className="!font-semibold !text-xl">The Strategy of a Chess Grandmaster</p>
                  <p className="pt-2 text-center">Patent litigation is a strategic game. We anticipate counterarguments, think several moves ahead, and position your invalidity evidence to deliver maximum impact.</p>
                </div>
              </div>
            </div>
            <div className='flex items-start justify-between gap-10 mb-10'>
              <h2 className='w-1/2 font-customFont3 font-semibold text-3xl'>An Unrelenting Pursuit of the <span className='font-customFont3 font-semibold text-3xl text-red-700'>Perfect Prior Art</span></h2>
              <p className='w-1/2'>
              Invalidity searches are not about finding as much prior art as possible—they’re about <span className='font-customFont2 text-red-700'>finding the right prior art.</span> The one piece of evidence that withstands scrutiny, aligns with legal strategy, and disrupts the opposition’s case.
              </p>
            </div>
            <div className='flex items-end justify-between gap-10 mb-5'>
              <div className='w-1/2'>
                <img className='h-40 w-11/12 object-cover mb-16' src={`/solution_img/lic-quote.jpg`} alt=''/>
                <div className='relative'>
                  <img className='absolute opacity-25 w-20 -top-10 -left-5' src={`insights_img/casestudy-quotes.png`} alt=''/>
                  <h2 className='font-customFont2 font-normal !text-xl'>We aren’t satisfied until we’ve explored every possible angle, exhausted every source, and tested every argument—because that’s what it takes to win in today’s high-stakes patent litigation landscape.</h2>
                </div>
              </div>
              <div className='w-1/2'>
                <p>At ExpertLancing, we:</p>
                <ul>
                  <li className='list-custom-bullet'><span className='font-customFont2 font-normal text-red-700'>Think Beyond Standard Search Methods</span> – While others rely on databases, we go deeper — technical literature, product documentation, industry archives, trade publications, and foreign-language sources that most firms overlook.</li>
                  <li className='list-custom-bullet'><span className='font-customFont2 font-normal text-red-700'>Don’t Just Provide Results</span> — We Provide an Invalidity Strategy – Every search is conducted with courtroom arguments in mind, ensuring that the prior art found is usable, defensible, and strategically valuable.</li>
                  <li className='list-custom-bullet'><span className='font-customFont2 font-normal text-red-700'>Operate With a Zero-Compromise Mindset</span> – We treat each case as if it were our own, knowing that even the slightest detail in a claim chart could determine the case outcome.</li>
                  <li className='list-custom-bullet'><span className='font-customFont2 font-normal text-red-700'>Challenge Assumptions and Break Down Barriers</span> – If prior art exists, we will find it. If it doesn’t exist, we will still find a way to weaken the claims.</li>
                </ul>
              </div>
            </div>
          </div>
      ),
  };   
  const handleTabChange = (id) => {
      setActiveTab(id);
  };
  

  return (
      <section className='flex flex-col items-center justify-center'>
          <Helmet>
            <title>Litigation Grade Invalidity Search</title>
            {/* <meta name='description' content='Our team of experts at ExpertLancing excels in guiding businesses through industry challenges with foresight and precision. From market expansion to technology roadmapping, we craft solutions that fuel innovation and secure leadership.' /> */}
          </Helmet>
          <h1 className='text-[4em] font-customFont3 p-5 w-full'>Litigation Grade Invalidity Search</h1>
          <div className='bg-red-600 flex items-start justify-between gap-10 px-10 py-5' id='section-details'>
              <h2 className='text-[2em] font-semibold text-white w-[48%]'>When the difference between victory and defeat is a single piece of prior art, we are the team you call.</h2>
              <p className='text-white w-[48%] font-customFont2 font-light'>
                At ExpertLancing, we don’t just conduct prior art searches—we deliver decisive, litigation-driven research that stands up in court. Our unmatched technical expertise is reinforced 
                by a deep understanding of jurisdiction-specific legal frameworks, ensuring that every piece of evidence we uncover aligns with the procedural and substantive nuances of patent law.
                <br/><br/>We bridge the gap between legal strategy and technical validation, equipping law firms with more than just data—we provide bulletproof insights that shape litigation outcomes.                  
                <br/><br/>If your case demands precision, depth, and ironclad invalidity arguments, ExpertLancing is the partner you need.
              </p>
          </div>
          <div className='sticky top-14 bg-white z-50 w-full shadow-sm xs:max-sm:hidden'>
              <ul className='flex items-center justify-center gap-28 w-full py-5'>
                  <li><a href='#section-details' className={`${activeTab === 'tab1' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab1')}>What we do</a></li>
                  <li><a href='#section-details' className={`${activeTab === 'tab2' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab2')}>Who we serve</a></li>
                  <li><a href='#section-details' className={`${activeTab === 'tab3' ? 'active-tab' : ''}`} onClick={() => handleTabChange('tab3')}>How we do it differently</a></li>
              </ul>
          </div>
          <div className='hyperlink !p-2 mt-10' id='tabs'>
              {tabContents[activeTab]}
          </div>
          <div className='bg-red-600 flex items-start justify-between gap-5 p-10 my-10'>
              <h2 className='text-[2em] font-semibold text-white w-1/2'>Patent litigation is a battlefield, where the right prior art can make the difference between success and failure.</h2>
              <div className='flex flex-col items-start gap-5 w-1/2'>
                <p className='text-white font-customFont2 font-light'>
                 At ExpertLancing, we don’t just find prior art, we find the prior art that wins cases.<br/>We bridge the gap between legal strategy and technical validation, equipping law firms with more than just data—we provide bulletproof insights that shape litigation outcomes.                  
                  <br/><br/>Discuss your invalidity search needs with our Experts today.
                </p>
                <Link className='bg-white text-[#464646] p-2 hover:text-red-700' to={`/contact`}>Contact Us</Link>
              </div>
          </div>
          


          <hr className='mt-10' id="related" />
          <h3 className='text-[1.5rem] font-customFont3 w-full text-left pl-12 xs:max-sm:text-[1.2em] font-normal my-10'>
            Related Articles
          </h3>
          <div className="slider-container w-11/12">
            {insightsSlider.length > 0 ? (
              <Slider {...settings}>{insightsSlider}</Slider>
            ) : (
              <p>No related articles found for "Litigation Grade Research".</p>
            )}
          </div>
      </section>
    )
}

