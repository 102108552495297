import React from 'react'
import "./brands.css";

export const Brands = () => {
  return (
    <div class="logos">
      <div class="logos-slide">
        <img src={`/solution_img/brand1.png`} alt={``} />
        <img src={`/solution_img/brand2.png`} alt={``} />
        <img src={`/solution_img/brand3.png`} alt={``} />
        <img src={`/solution_img/brand4.png`} alt={``} />
        <img src={`/solution_img/brand5.png`} alt={``} />
        <img src={`/solution_img/brand6.png`} alt={``} />
        <img src={`/solution_img/brand7.png`} alt={``} />
        <img src={`/solution_img/brand8.png`} alt={``} />
        <img src={`/solution_img/brand9.png`} alt={``} />
        <img src={`/solution_img/brand10.png`} alt={``} />
        <img src={`/solution_img/brand11.png`} alt={``} />
      </div>
      <div class="logos-slide">
        <img src={`/solution_img/brand11.png`} alt={``} />
        <img src={`/solution_img/brand12.png`} alt={``} />
        <img src={`/solution_img/brand13.png`} alt={``} />
        <img src={`/solution_img/brand14.png`} alt={``} />
        <img src={`/solution_img/brand15.png`} alt={``} />
        <img src={`/solution_img/brand16.png`} alt={``} />
        <img src={`/solution_img/brand17.png`} alt={``} />
        <img src={`/solution_img/brand18.png`} alt={``} />
        <img src={`/solution_img/brand19.png`} alt={``} />
        <img src={`/solution_img/brand20.png`} alt={``} />
        <img src={`/solution_img/brand21.png`} alt={``} />
        <img src={`/solution_img/brand22.png`} alt={``} />
      </div>
    </div>
  )
}
