import { Helmet } from 'react-helmet-async';
import peopleBanner from "../assets/Our_People_banner.jpg";
import GD from "../assets/people-imgs/GD-bgremoved.png";
import MD from "../assets/people-imgs/MD-bgremoved.png";
import VP from "../assets/people-imgs/Vinod.png";
import Nitima from "../assets/people-imgs/Nitima.png";
import Neha from "../assets/people-imgs/Neha Sharma.png";
import Pallavi from "../assets/people-imgs/202 - PALLAVI PRASAD.jpg";
import Kapil from "../assets/people-imgs/130 - Kapil Chauhan.png";
import Rajat from "../assets/people-imgs/Rajat.png";
import Ashish from "../assets/people-imgs/233- Ashish Kumar Singh.png";
import Nimit from "../assets/people-imgs/107 - Nimit Agarwal.png";
import Nikhil from "../assets/people-imgs/Nikhil Kaker.png";
import Abhishek from "../assets/people-imgs/286_-_Abhishek_Patel.png";
import Shubham from "../assets/people-imgs/139 - SHUBHAM CHAUDHARY.png";
import Mounak from "../assets/people-imgs/187 - Mounak Das.png";
import Nitish from "../assets/people-imgs/158 - Nitish Jaswal.png";
import NehaT from "../assets/people-imgs/Neha Tomer.png";
import Kailash from "../assets/people-imgs/192 - Kailash Nath Yadav.png";
import Yavnica from "../assets/people-imgs/193 - Yavnica.png";
import bdicon from "../assets/business_development_dept.png";
import opsicon from "../assets/ops_dept.png";
import rndicon from "../assets/research_and_development_dept.png";
import hricon from "../assets/HRicon.png";
import mricon from "../assets/marketing-strategy.png";
import marcomicon from "../assets/MarcomIcon.png";
import News from "../components/newsletter";
import "../pages/Ourpeople.css";

const Ourpeople = () =>{
    return(
        <section className="flex flex-col items-center">
            <Helmet>
            <title>Our People</title>
            <meta name='description' content='Our team of experts at ExpertLancing excels in guiding businesses through industry challenges with foresight and precision. From market expansion to technology roadmapping, we craft solutions that fuel innovation and secure leadership.' />
            </Helmet>
            <div class='ind-banner relative bg-slate-950 text-white h-96 overflow-hidden w-full'>
                <img class='brightness-50 h-full w-full object-cover' src={peopleBanner} alt="" />
                <div class="absolute z-40 top-2 left-2 xs:max-sm:left-0 xs:max-sm:px-5 flex flex-col w-8/12 xs:max-sm:w-full justify-center items-start p-8">
                    <div className="tracking-widest text-[3em] font-semibold text-[white] uppercase xs:max-sm:text-[1.8em]">
                        <h2>Leaders At ExpertLancing</h2>
                    </div>
                    <div className="text-sm xs:max-sm:text-[14px] w-fit">
                        <p>
                            Our team of experts at ExpertLancing excels in guiding businesses through 
                            industry challenges with foresight and precision. From market expansion to 
                            technology roadmapping, we craft solutions that fuel innovation and secure leadership.
                        </p>
                    </div>
                </div>
            </div>
            <p className="w-9/12 text-justify py-20 xs:max-sm:text-sm xs:max-sm:py-10">
                ExpertLancing provides cutting-edge consultancy and advisory services in the chemical industry by 
                leveraging market research, patent analytics, and technology insights. We assist in identifying 
                emerging trends, optimizing production processes, and ensuring regulatory compliance. Our expertise 
                helps clients enhance product performance, reduce environmental impact, and stay ahead in a competitive market.
            </p>
            <div className="flex flex-col items-center justify-center relative w-full xs:max-sm:hidden ">
                <div className="flex flex-col gap-10 items-center w-9/12">
                <div className="overflow-hidden flex items-center justify-center gap-5 bg-white w-full h-56 border rounded z-20 p-5">
                        <div className="w-9/12 flex flex-col items-start justify-between gap-2">
                            <h2>Manasi Dhawan</h2>
                            <p className="text-xs">CEO & Head of Client Delivery</p>
                            <p className="text-xs text-justify">
                                Ms. Dhawan leads a team specializing in trademark strategies, IP asset monetization, patent litigation, and technology 
                                intelligence. As one of the few women entrepreneurs in the IP field, she has gained recognition from Niti Aayog for her 
                                industry contributions. With over 14 years of experience in patents, Ms. Dhawan excels as CEO, demonstrating exceptional 
                                skills in strategy development that involve market research, complex technology, and competitor analysis. She actively 
                                supports clients in managing their IP assets, offering expertise in patent drafting, prosecution, litigation, and strategic 
                                solutions. Her global clientele includes Fortune 500 companies and top law firms.
                            </p>
                        </div>
                        <div className="w-3/12">
                            <img className=" " src={MD} alt="" />
                        </div>
                    </div>
                    <div className="overflow-hidden flex items-center justify-center gap-5 bg-white w-full h-56 border rounded z-20 p-5">
                        <div className="w-2/12">
                            <img className="" src={GD} alt="" />
                        </div>
                        <div className="w-10/12 flex flex-col items-start justify-between gap-2">
                            <h2>Gaurav Dhawan</h2>
                            <p className="text-xs">CSO, Sales</p>
                            <p className="text-xs text-justify">
                                He is a key member of the founding team at Expertlancing and has been there since its inception.
                                With an extensive career span of 14+ years in managing sales and business development, he provisions 
                                creating new opportunities and delivering successful outcomes. Having in-depth knowledge of market 
                                trends, he has played a pivotal role in mentoring team members to maintain high standards of performance 
                                along with client relationships and brand development. Being an innovator by nature, he completed his Master’s 
                                degree in Management and Bachelor’s degree in Mechanical Engineering to pursue his dream to provide a scalable 
                                business model to a firm that supports inventions collaterally.
                            </p>
                        </div>
                    </div>
                    <div className="overflow-hidden flex items-center justify-center gap-5 bg-white w-full h-56 border rounded z-20 p-5">
                        <div className="w-10/12 flex flex-col items-start justify-between gap-2">
                            <h2>Vinod Singh</h2>
                            <p className="text-xs">VP, Strategic Solutions</p>
                            <p className="text-xs text-justify">
                                With over 20 years of expertise in IP, business consultancy, automations, and product development, Mr. Vinod Kumar Singh is a 
                                key leader in driving innovation and delivering impactful solutions. His career is marked by a relentless pursuit of leveraging 
                                cutting-edge technologies to stay ahead of industry trends and propel organizations towards sustained success. Mr. Singh has a 
                                proven track record of leading cross-functional teams, developing forward-thinking strategies, and surpassing client expectations. 
                                He excels in identifying emerging market opportunities and transforming complex data into actionable insights for operational 
                                efficiency and competitive advantage. His strategic vision and commitment to client-centricity ensure solutions that not only 
                                meet but exceed client needs.
                            </p>
                        </div>
                        <div className="w-2/12">
                            <img className="" src={VP} alt="" />
                        </div>
                    </div>
                </div>
                <div className="red-bg"></div>
            </div>
            <div className="w-9/12 py-20 grid grid-cols-3 auto-rows-auto gap-20 xs:max-sm:grid-cols-1 xs:max-sm:gap-10 xs:max-sm:py-10 xs:max-sm:w-10/12">
                <div className="hidden people-cards xs:max-sm:flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-right" src={MD} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={opsicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Manasi Dhawan</p>
                    <p className="text-xs text-left w-full">CEO & Head of Client Delivery</p>
                </div>
                <div className="hidden people-cards xs:max-sm:flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={GD} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={bdicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Gaurav Dhawan</p>
                    <p className="text-xs text-left w-full">CSO, Sales</p>
                </div>
                <div className="hidden people-cards xs:max-sm:flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-28 w-28 object-cover object-top" src={VP} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={mricon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Vinod Singh</p>
                    <p className="text-xs text-left w-full">VP, Strategic Solutions</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-40 xs:max-sm:w-40 object-cover object-center" src={Nitima} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={opsicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Nitima Sharma</p>
                    <p className="text-xs text-left w-full">Associate Vice President</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-72 w-72 xs:max-sm:h-40 xs:max-sm:w-40 object-cover object-top" src={Neha} alt="" />
                        </div>
                        <img className="w-10 absolute p-1 py-[7px]  bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={hricon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Neha Sharma</p>
                    <p className="text-xs text-left w-full">Head - Human Resources</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={Pallavi} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 py-[10px]  bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={marcomicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Pallavi Prasad</p>
                    <p className="text-xs text-left w-full">Head - MARCOM</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={Kapil} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={opsicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Kapil Chauhan</p>
                    <p className="text-xs text-left w-full">Group Manager</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-56 w-56 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={Rajat} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={opsicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Rajat Bhiwania</p>
                    <p className="text-xs text-left w-full">Group Manager</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-64 w-64 xs:max-sm:h-44 xs:max-sm:w-44 object-cover object-top" src={Ashish} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={bdicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Ashish Kumar Singh</p>
                    <p className="text-xs text-left w-full">Senior Consultant - Client Success & Growth</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={Nimit} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={bdicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Nimit Agarwal</p>
                    <p className="text-xs text-left w-full">Sr. Consultant - Client Development</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-56 w-56 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={Nikhil} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={bdicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Nikhil Kaker</p>
                    <p className="text-xs text-left w-full">Senior Consultant - Client Success & Growth</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={Abhishek} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={mricon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Abhishek Patel</p>
                    <p className="text-xs text-left w-full">Consultant - Business Consultant</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={Shubham} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={opsicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Shubham Chaudhary</p>
                    <p className="text-xs text-left w-full">Team Lead</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={Mounak} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={opsicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Mounak Das</p>
                    <p className="text-xs text-left w-full">Team Lead</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={Nitish} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={opsicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Nitish Jaswal</p>
                    <p className="text-xs text-left w-full">Team Lead</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={NehaT} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={bdicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Neha Tomer</p>
                    <p className="text-xs text-left w-full">Consultant - Client Success & Growth</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={Kailash} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={rndicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Kailash Nath Yadav</p>
                    <p className="text-xs text-left w-full">Technology Lead</p>
                </div>
                <div className="people-cards flex flex-col items-center justify-start p-10 xs:max-sm:p-5 xs:max-sm:w-full">
                    <div className="people-img relative">
                        <div className="people-circle h-44 w-44 overflow-hidden rounded-full xs:max-sm:h-28 xs:max-sm:w-28">
                            <img className="h-44 w-44 xs:max-sm:h-36 xs:max-sm:w-36 object-cover object-top" src={Yavnica} alt="" />
                        </div>
                        <img className="w-10 absolute p-2 bottom-0 right-2 bg-red-800 rounded-full shadow-md" src={rndicon} alt=""></img>
                    </div>
                    <p className="pt-5 pb-2 text-left w-full text-lg font-semibold">Yavnica</p>
                    <p className="text-xs text-left w-full">Technology Lead</p>
                </div>
            </div>
            <News />
        </section>
    )
}

export default Ourpeople